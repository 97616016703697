<template>
    <div class="card card-custom">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->

        <div class="card-body" style="max-height:500px;overflow:auto;">

            <div class="form-group table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                    <tr class="alert alert-light">
                        <th scope="col" width="80px;">
                            <label class="checkbox checkbox-lg checkbox-single">
                                <input type="checkbox" v-model="checked"/>
                                <span></span>
                            </label>
                        </th>
                        <th scope="col">SKU</th>
                        <th scope="col">价格</th>
                        <th scope="col" width="150px">数量</th>
                    </tr>
                    </thead>
                    <tbody>

                    <template v-for="(item, idx) in data_form.skus">
                        <tr :key="idx">
                            <td>
                                <label class="checkbox checkbox-lg checkbox-single">
                                    <input type="checkbox" :value="item.id" v-model="data_form.skus[idx].checked"/>
                                    <span></span>
                                </label>
                            </td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="symbol symbol-40 flex-shrink-0">
                                        <div class="symbol-label"
                                             :style="'background-image:url('+item.image_url+')'"></div>
                                        </div>
                                    <div class="ml-2">
                                        <div class="text-dark-75 font-weight-bold line-height">
                                            {{item.sku}}
                                        </div>
                                        <div class="font-size-sm text-dark-50 text-hover-primary">
                                            <template v-for="attr in JSON.parse(item.attr_info)">
                                                <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{{item.net_price}}</td>
                            <td>
                                <input type="text" @input="onIntInput" name="quantity"  v-model="data_form.skus[idx].quantity" class="form-control" placeholder="">
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>

        </div>

        <div class="card-footer">
            <button class="btn btn-primary mr-2" type="button" @click="saveForm()">保存</button>
            <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
        </div>

        <!--end::Form-->
    </div>
</template>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import KTDatatable from "@/view/content/tables/Datatable.vue";
    import {getProductSku} from '@/api/order';

    export default {
        name: "add_productsku_dialog",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {
            var _this = this;
            getProductSku({'product_id': _this.dialog_data.product_id}).then(function(res) {
                if(res && res.response) {
                    _this.data_form.skus = res.response;
                }
            });
        },
        components :{

        },
        data() {
            return {
                checked: false,
                data_form:{
                    skus: []
                },
            }
        },
        mounted() {


        },
        watch: {
            'checked': function(val){
                var _this = this;
                _this.data_form.skus.forEach(function(value){
                    value.checked = _this.checked;
                });
            }
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);
            },
            saveForm() {
                //选择已经checked的sku
                var _this = this;
                var sku = [];
                this.data_form.skus.forEach(function(value){
                    if(value.checked && value.quantity){
                        value.product_title = _this.dialog_data.product_title;
                        sku.push(value);
                    }
                });
                this.$emit('opeareResultHandler', sku);
            },
            onIntInput(e){
                var value = parseInt(e.target.value) || null;
                e.target.value = value;
            },
        }
    }
</script>
