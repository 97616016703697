<template>
    <v-app style="background: transparent" :class="{}">
        <!-- begin:: Content Head -->
        <KTSubheader
                v-if="nav.subheaderDisplay"
                v-bind:breadcrumbs="nav.breadcrumbs"
                v-bind:title="nav.pageTitle"
        />
        <!-- end:: Content Head -->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">

                <form class="form fv-plugins-bootstrap fv-plugins-framework" id="dataForm" novalidate="novalidate">

                    <div class="card card-custom example example-compact">
                        <div class="card-header">
                            <h3 class="card-title">产品信息</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">所属平台 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <b-form-select class="form-control" v-model="order.platform_id" :options="plat_form_list"  name="platform_id" disabled="disabled">

                                    </b-form-select>
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">所属站点 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <b-form-select class="form-control" v-model="order.site_id" :options="site_list"  name="site_id" disabled="disabled">

                                    </b-form-select>
                                </div>
                            </div>

                            <div class="form-group row" v-if="order.type == 2">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">产品信息 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4">
                                    <treeselect
                                            :load-options="loadOptions"
                                            :default-expand-level="1"
                                            :async="true"
                                            placeholder="请选择产品"
                                            v-model="order.product_id"
                                            @select="selectProduct"
                                    />
                                    <input type="hidden" v-model="order.product_id" name="product_id"/>
                                </div>
                                <div class="col-lg-4">
                                    <button class="btn btn-primary" type="button" @click="addProductSku()">添加</button>
                                </div>
                            </div>
                            <div class="form-group row" v-if="order.details.length > 0">
                                <table class="table table-vertical-center">
                                    <thead>
                                    <tr class="alert alert-light">
                                        <th scope="col">产品信息</th>
                                        <th scope="col" width="100px">价格(USD)</th>
                                        <th scope="col" width="100px">数量</th>
                                        <th scope="col" width="100px">金额({{order.currency}})</th>
                                        <th scope="col" width="50px">操作</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <template v-for="(item, idx) in order.details">
                                        <tr :key="idx">
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="symbol symbol-60 flex-shrink-0">
                                                        <div class="symbol-label"
                                                             :style="'background-image:url('+item.image_url+')'"></div>
                                                    </div>
                                                    <div class="ml-2">
                                                        <div class="text-dark-75 font-weight-bold line-height">
                                                            {{item.product_title}}
                                                        </div>
                                                        <div class="text-dark-75 line-height">
                                                            {{item.sku}}
                                                        </div>
                                                        <div class="font-size-sm text-dark-50 text-hover-primary">
                                                            <template v-for="attr in JSON.parse(item.attr_info)">
                                                                <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                            <td>
                                                <template v-if="order.type == 2">
                                                    <input type="text" @input="onFloatInput" name="price"  v-model="order.details[idx].net_price" class="form-control" placeholder="">
                                                </template>
                                                <template v-else>
                                                    {{item.net_price}}
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="order.type == 2">
                                                    <input type="text" @input="onIntInput" name="quantity"  v-model="order.details[idx].quantity" class="form-control" placeholder="">
                                                </template>
                                                <template v-else>
                                                    {{item.quantity}}
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="order.type == 2">
                                                    <input type="text" disabled="disabled" name="amount"  v-model="order.details[idx].amount" class="form-control" placeholder="">
                                                </template>
                                                <template v-else>
                                                    {{item.amount}}
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="order.type == 2">
                                                    <a href="javascript:void(0);" @click="deleteProductSku(idx)" class="btn btn-sm btn-clean btn-icon mr-2" title="删除">
                                                        <i class="la la-trash-o icon-lg"></i>
                                                    </a>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!--end::Card-->

                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">支付信息</h3>
                        </div>
                        <div class="card-body">

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">订单币种 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <select class="form-control tt-input"  v-model="order.currency" ame="currency" :disabled="order.type == 2?'':'disabled'">
                                        <template v-for="(c, idx) in currency_list">
                                            <option :key="idx" :value="c.code">{{c.code}}</option>
                                        </template>
                                    </select>

                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">产品总价 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           @input="onFloatInput"
                                           name="total_amount"
                                           v-model="order.total_amount">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">手续费:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           @input="onFloatInput"
                                           name="fee"
                                           v-model="order.fee">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">订单运费:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           @input="onFloatInput"
                                           name="freight"
                                           v-model="order.freight">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">折扣金额:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           @input="onFloatInput"
                                           name="coupon"
                                           v-model="order.coupon">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">税金额:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           @input="onFloatInput"
                                           name="tax"
                                           v-model="order.tax">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">订单总金额:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           @input="onFloatInput"
                                           name="amount"
                                           v-model="order.amount">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">支付方式 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           name="channel"
                                           v-model="order.channel">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">支付账号 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           name="pay_account"
                                           v-model="order.pay_account">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">支付金额 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           name="pay_amount"
                                           v-model="order.pay_amount">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">收款账号 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           name="get_account"
                                           v-model="order.get_account">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Card-->

                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">客户信息</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">客户账号 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           name="user_account"
                                           v-model="order.user_account">
                                </div>
                            </div>
                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">客户IP:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           name="ip"
                                           v-model="order.ip">
                                </div>
                            </div>
                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">客户国家:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           name="country"
                                           v-model="order.country">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">联系邮箱:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.type == 2?false:'disabled'"
                                           type="text"
                                           name="contact_email"
                                           v-model="order.contact_email">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">收货地址<span class="text-danger">*</span> :</label>
                                <div class="col-lg-3 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.status <= 1?false:'disabled'"
                                           type="text"
                                           name="username"
                                           placeholder="用户名"
                                           v-model="order.address.username">
                                </div>
                                <div class="col-lg-3 col-md-9 col-sm-12">
                                    <el-select v-model="order.address.country_code" filterable placeholder="国家"  name="country_code" style="width:100%" :disabled="order.status <= 1?false:'disabled'">
                                        <el-option
                                                v-for="item in country_list"
                                                :key="item.value"
                                                :label="item.text"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>


                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right"></label>
                                <div class="col-lg-3 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.status <= 1?false:'disabled'"
                                           type="text"
                                           name="province"
                                           placeholder="州/省/郡"
                                           v-model="order.address.province">
                                </div>
                                <div class="col-lg-3 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.status <= 1?false:'disabled'"
                                           type="text"
                                           name="city"
                                           placeholder="城市"
                                           v-model="order.address.city">
                                </div>

                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right"></label>
                                <div class="col-lg-3 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.status <= 1?false:'disabled'"
                                           type="text"
                                           name="province"
                                           placeholder="邮编"
                                           v-model="order.address.postcode">
                                </div>
                                <div class="col-lg-3 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.status <= 1?false:'disabled'"
                                           type="text"
                                           name="phone"
                                           placeholder="手机"
                                           v-model="order.address.phone">
                                </div>
                            </div>
                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right"></label>
                                <div class="col-lg-6 col-md-9 col-sm-12">
                                    <input class="form-control tt-input" :disabled="order.status <= 1?false:'disabled'"
                                           type="text"
                                           name="address"
                                           placeholder="详细地址"
                                           v-model="order.address.address">
                                </div>
                            </div>

                            <div class="form-group row fv-plugins-icon-container">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">备注：</label>
                                <div class="col-lg-6 col-md-9 col-sm-12">
                                    <textarea  class="form-control tt-input" name="note" v-model="order.note"></textarea>
                                </div>
                            </div>
                        </div>


                        <div class="card-footer">
                            <div class="row">
                                <div class="col-lg-9 ml-lg-auto">
                                    <button v-if="order.status <= 1" ref="data_form_submit" type="submit" class="btn btn-primary mr-2"> 保存</button>
                                    <button type="button" @click="back()" class="btn btn-light-primary">返回</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--end::Card-->
                </form>
            </div>
        </div>

        <v-dialog v-model="dialog.show" persistent max-width="700px">
            <component
                    :key="dialog.id"
                    :title="dialog.title"
                    :visible="dialog.show"
                    :is="dialog.view"
                    :dialog_data="dialog.data"
                    @opeareResultHandler="opeareResultHandler"></component>
        </v-dialog>

    </v-app>
</template>

<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTUtil from "@/assets/js/components/util";
    import TEditor from '@/view/content/tinymce/Index'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Treeselect from '@riophae/vue-treeselect'
    import {getSiteListByPlatForm, getProduct, getOrder, getCurrency, updateOrder} from '@/api/order';
    import addProductSku from '@/view/pages/order/AddProductSku';

    export default {
        name: "show_order",
        components: {
            KTSubheader,
            TEditor,
            Treeselect
        },
        created: function(){
            var _this = this;
            _this.country_list = KTUtil.listToSelectOptions(KTUtil.getCountryList(), 'locale', 'en');
            _this.country_list[0].text = '国家';
            getCurrency({}).then(function(res) {
                if(res && res.response) {
                    _this.currency_list = res.response;
                }
            });
            getOrder({'order_id': _this.$route.query.id}).then(function(res) {
                if(res && res.response) {
                    _this.order = res.response;
                }
            });
        },
        watch: {
            'order.platform_id': function(){
                var _this = this;
                getSiteListByPlatForm({'site_platform': _this.order.platform_id}).then(function(res) {
                    if(res && res.response){
                        var list = [];
                        res.response.forEach(function(value){
                            list.push({'value': value.id, 'text': value.site_name});
                        });
                        _this.site_list = list;
                    }
                });
            },
            'order.details': {
                handler (val, oldval) {
                    var _this = this;
                    if(_this.order.details && _this.order.details.length > 0){
                        _this.input_diasabled = true;
                    } else {
                        _this.input_diasabled = false;
                    }
                    _this.calculateAmount();
                },
                deep: true
            },
            'order.tax': function(){
                this.calculateAmount();
            },
            'order.coupon': function(){
                this.calculateAmount();
            },
            'order.freight': function(){
                this.calculateAmount();
            },
            'order.fee': function(){
                this.calculateAmount();
            },
            'order.currency': function() {
                this.calculateAmount();
            }
        },
        data() {
            return {
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/order', 'title':'订单管理'}, {'id':'', 'route':'/order/updateorder', 'title':'修改订单'}],
                    pageTitle: '订单'
                },
                plat_form_list: KTUtil.getPlatForm(),
                site_list: [],
                currency_list: [],
                country_list: [],
                input_diasabled: false,
                order: {
                    id: '',
                    platform_id: '',
                    site_id: '',
                    product_id: null,
                    product_title: '',
                    details: [],
                    currency: 'USD',
                    origin_base: '',
                    total_amount: '',
                    fee: '',
                    freight: '',
                    coupon: '',
                    tax: '',
                    amount: '',
                    channel: '',
                    pay_account: '',
                    pay_amount: '',
                    get_account: '',
                    user_account: '',
                    ip: '',
                    country: '',
                    contact_email: '',
                    address: {
                        username: '',
                        country_code: null,
                        province: '',
                        city: '',
                        postcode: '',
                        phone: '',
                        address: ''
                    },
                    note: ''
                },

                dialog: {
                    id: 0,
                    show: false,
                    title: '',
                    view: null,
                    data: null
                }

            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    platform_id: {
                        validators: {
                            notEmpty: {
                                message: "请选择订单所属平台"
                            }
                        }
                    },

                    site_id: {
                        validators: {
                            notEmpty: {
                                message: "请选择订单所属站点"
                            }
                        }
                    },

                    product_id: {
                        validators: {
                            notEmpty: {
                                message: "请输入产品信息"
                            }
                        }
                    },
                    total_amount: {
                        validators: {
                            notEmpty: {
                                message: "请输入产品总价"
                            }
                        }
                    },
                    channel: {
                        validators: {
                            notEmpty: {
                                message: "请输入支付方式"
                            }
                        }
                    },
                    pay_account: {
                        validators: {
                            notEmpty: {
                                message: "请输入支付账号"
                            }
                        }
                    },
                    pay_amount: {
                        validators: {
                            notEmpty: {
                                message: "请输入支付金额"
                            }
                        }
                    },
                    get_account: {
                        validators: {
                            notEmpty: {
                                message: "请输入收款账号"
                            }
                        }
                    },
                    user_account: {
                        validators: {
                            notEmpty: {
                                message: "请输入客户账号"
                            }
                        }
                    },
                    username: {
                        validators: {
                            notEmpty: {
                                message: "请输入用户名"
                            }
                        }
                    },
                    country_code: {
                        validators: {
                            notEmpty: {
                                message: "请选择收货国家"
                            }
                        }
                    },
                    province: {
                        validators: {
                            notEmpty: {
                                message: "请选择收货省地址"
                            }
                        }
                    },
                    city: {
                        validators: {
                            notEmpty: {
                                message: "请选择收货市地址"
                            }
                        }
                    },
                    postcode: {
                        validators: {
                            notEmpty: {
                                message: "请选择收货地址邮编"
                            }
                        }
                    },
                    phone: {
                        validators: {
                            notEmpty: {
                                message: "请选择收货联系电话"
                            }
                        }
                    },
                    address: {
                        validators: {
                            notEmpty: {
                                message: "请选择收货详细地址"
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                updateOrder(_this.order).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$router.push({path: '/order'});
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {

            opeareResultHandler(result) {
                var _this = this;
                if(result && result.length > 0){
                    result.forEach(function(value){
                        _this.order.details.push(value);
                    });

                }
                this.dialog.show = false;
            },
            onFloatInput(e){
                e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            },
            onIntInput(e){

                e.target.value = parseInt(e.target.value) || null;
            },
            loadOptions({ action, searchQuery, callback }) {
                if (action === 'ASYNC_SEARCH' && this.order.site_id) {
                    getProduct({'product_title': searchQuery, 'site_id': this.order.site_id}).then(function (res) {
                        if(res && res.response){
                            var options = [];
                            res.response.forEach(function(value){
                                options.push({'id': value.id, 'label': value.site_name +'  '+ value.product_title});
                            });
                            callback(null, options);
                        }
                    });
                } else {
                    KTUtil.toast(this, '操作提醒', '请选择站点', 'danger');
                    return;
                }

            },

            selectProduct(val){
                this.order.product_title = val.label;
            },
            addProductSku() {
                if (!this.order.product_id) {
                    KTUtil.toast(this, '操作提醒', '请选择产品', 'danger');
                    return;
                }
                this.dialog.id = new Date().getTime();
                this.dialog.show = true;
                this.dialog.title = '添加 '+ this.order.product_title +' 产品SKU';
                this.dialog.view = addProductSku;
                this.dialog.data = {
                    product_id: this.order.product_id,
                    product_title: this.order.product_title
                }
            },
            calculateAmount() {
                var _this = this;
                var amount = 0;

                //获取汇率
                if(_this.order.currency){
                    _this.currency_list.forEach(function(value){
                        if(value.code == _this.order.currency){
                            _this.order.origin_base = value.currency;
                            return true;
                        }
                    });
                }


                _this.order.details.forEach(function(value, index){
                    var sum = value.quantity * value.net_price / _this.order.origin_base;
                    if(sum) {
                        amount += sum;
                        _this.order.details[index].amount = sum.toFixed(2);
                    }
                });
                _this.order.total_amount = amount?amount.toFixed(2):'';

                if(_this.order.fee) {
                    amount += parseFloat(_this.order.fee);
                }
                if(_this.order.coupon) {
                    amount -= parseFloat(_this.order.coupon);
                }
                if(_this.order.tax) {
                    amount += parseFloat(_this.order.tax);
                }
                if(_this.order.freight) {
                    amount += parseFloat(_this.order.freight);
                }
                _this.order.pay_amount = amount?amount.toFixed(2):'';
                _this.order.amount = amount?amount.toFixed(2):'';
            },
            deleteProductSku(index) {
                this.order.details.splice(index, 1);
            },
            back() {
                this.$router.push({path: '/order'});
            },
        },

    }
</script>
